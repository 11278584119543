import {createContext, useContext} from 'react'

interface AppContextProps {
  darkMode: boolean
}

export const AppContext = createContext<AppContextProps>({
  darkMode: false,
})

export const useAppContext = (): AppContextProps => {
  return useContext(AppContext)
}
