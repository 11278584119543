import React, {HTMLProps, useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Link} from 'gatsby'
import DarkModeToggle from 'react-dark-mode-toggle'
import ScrollLink from './ScrollLink'
import logo from '../images/logo-simple.svg'
import logoFull from './../images/logo.svg'
import logoGoogle from './../images/logo_google.png'
import googleSD from './../images/google_sd.jpg'
import Button from './Button'
import Drawer from './Drawer'
import {AppContext} from '../context'

export interface LayoutProps extends HTMLProps<HTMLDivElement> {
  mainPage?: boolean
}

const getScrollPosition = () => {
  return typeof window !== 'undefined' ? {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop
  } : {x: 0, y: 0}
}

export default function Layout({children, mainPage = false, ...props}: LayoutProps) {
  const isClient = typeof window !== 'undefined'
  const [smallHeader, setSmallHeader] = useState<boolean>(false)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  // Initialize dark/bright mode
  const defaultDarkScheme = false
  const initWithDarkMode = isClient && localStorage?.getItem('dark')
    ? localStorage?.getItem('dark') === 'true'
    : (
      isClient && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? true
        : defaultDarkScheme
    )

  const [darkMode, setDarkMode] = useState<boolean>(initWithDarkMode)

  // Toggle dark mode and set preferences in local storage
  const toggleDarkMode = (): void => {
    setDarkMode(prevState => {
      const newMode = !prevState

      isClient && localStorage?.setItem('dark', newMode ? 'true' : 'false')

      return newMode
    })
  }

  // Set header size based on scroll position
  const setHeaderSize = (event?: Event): void => {
    event?.preventDefault()
    event?.stopPropagation()

    const scrollY = getScrollPosition()?.y
    const scrollThreshold = 100

    if (scrollY > scrollThreshold) {
      setSmallHeader(true)
    } else if (scrollY === 0) {
      setSmallHeader(false)
    }
  }

  useEffect(() => {
    // Change dark mode if system preferences change and dark mode is not set in local storage
    isClient && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if ( ! localStorage?.getItem('dark')) {
        setDarkMode(event.matches)
      }
    })

    // Set header size
    setHeaderSize()

    isClient && window.addEventListener('scroll', setHeaderSize)

    return () => {
      isClient && window.removeEventListener('scroll', setHeaderSize)
    }
  }, [])

  return (
    <AppContext.Provider value={{darkMode}}>
      <div {...props}>
        <Helmet
          titleTemplate="%s - Deine Fahrschule in Hünfeld"
          htmlAttributes={{
            lang: 'de',
            class: `
            relative
            ${darkMode ? 'dark' : 'bright'}
            ${drawerOpen ? 'overflow-hidden' : 'overflow-x-hidden'}
          `,
          }}
          bodyAttributes={{
            class: `
            relative bg-white dark:bg-dark-bg text-dark-text dark:text-light-text text-base transition-colors
          `
          }}
        >
          <title>Marco`s Fahrschule</title>
          <meta name="description" content="Mache deinen Führerschein in Hünfeld in den Klassen B, BE, L, AM, A1, A2, A und Mofa!"/>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Marco\`s Fahrschule",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Kaiserstraße 9",
                "addressLocality": "Hünfeld",
                "addressRegion": "HE",
                "postalCode": "36088",
                "addressCountry": "DE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 50.672720,
                "longitude": 9.764960
              },
              "logo": "https://marcos-fahrschule.de${logoGoogle}",
              "image": "https://marcos-fahrschule.de${googleSD}",
              "url": "https://marcos-fahrschule.de",
              "telephone": "+496652747777",
              "openingHoursSpecification": [
                {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": [
                    "Montag",
                    "Dienstag",
                    "Mittwoch",
                    "Donnerstag",
                    "Freitag"
                  ],
                  "opens": "14:00",
                  "closes": "18:30"
                }
              ]
            }
          `}
          </script>
        </Helmet>

        <div className="hidden lg:flex">
          <div className="container mx-auto flex justify-end items-center space-x-8 py-2 text-xs">
            <Link
              to="/impressum"
              className="hover:text-dark-text dark:hover:text-ci-yellow transition-colors"
            >
              Impressum
            </Link>
            <Link
              to="/datenschutz"
              className="hover:text-dark-text dark:hover:text-ci-yellow transition-colors"
            >
              Datenschutz
            </Link>
            {isClient && (
              <DarkModeToggle
                onChange={toggleDarkMode}
                checked={darkMode}
                size={50}
              />
            )}
          </div>
        </div>
        <header className="sticky top-0 z-[100] bg-ci-gray text-header-text text-base md:text-lg lg:text-xl 2xl:text-2xl transition-all">
          <div className={`container mx-auto flex items-center py-4 ${smallHeader ? 'md:py-2 lg:py-4' : 'lg:py-6'}`}>
            <div className="flex items-center space-x-2 md:space-x-8 lg:space-x-16">
              <ScrollLink
                enabled={mainPage}
                className="inline"
                href={`/`}
                onClick={event => {
                  event.preventDefault()

                  window?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }}
              >
                <img
                  src={logo}
                  alt="Marco`s Fahrschule"
                  width="1px"
                  height="1px"
                  className={`${smallHeader ? '!w-[57px]' : 'w-[57px] md:w-[73px] lg:w-[90px] xl:w-[120px]'} h-auto transition-all`}
                />
              </ScrollLink>
              <div className="flex items-center space-x-4 sm:space-x-8">
                <a
                  href="tel:06652 74 77 77"
                  className="group hidden md:flex lg:hidden xl:flex items-center space-x-4 hover:text-ci-yellow"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path fill="#DBDEE4" className="group-hover:fill-ci-yellow"
                          d="M3.54,2 C3.6,2.89 3.75,3.76 3.99,4.59 L2.79,5.79 C2.38,4.59 2.12,3.32 2.03,2 L3.54,2 M13.4,14.02 C14.25,14.26 15.12,14.41 16,14.47 L16,15.96 C14.68,15.87 13.41,15.61 12.2,15.21 L13.4,14.02 M4.5,0 L1,0 C0.45,0 0,0.45 0,1 C0,10.39 7.61,18 17,18 C17.55,18 18,17.55 18,17 L18,13.51 C18,12.96 17.55,12.51 17,12.51 C15.76,12.51 14.55,12.31 13.43,11.94 C13.33,11.9 13.22,11.89 13.12,11.89 C12.86,11.89 12.61,11.99 12.41,12.18 L10.21,14.38 C7.38,12.93 5.06,10.62 3.62,7.79 L5.82,5.59 C6.1,5.31 6.18,4.92 6.07,4.57 C5.7,3.45 5.5,2.25 5.5,1 C5.5,0.45 5.05,0 4.5,0 Z"/>
                  </svg>
                  <span>06652 - 74 77 77</span>
                </a>
                <ScrollLink
                  href="/kontakt"
                  enabled={mainPage}
                >
                  <Button
                    outlined
                    className="hidden xs:inline-flex lg:hidden"
                    aria-label="Schreibe uns!"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                      <g fill="none" fillRule="evenodd">
                        <polygon points="0 0 18 0 18 18 0 18"/>
                        <path fill="#FFF101" fillRule="nonzero"
                              d="M2.5,2.5 L14.5,2.5 L14.5,11.5 L3.3775,11.5 L2.5,12.3775 L2.5,2.5 M2.5,1 C1.675,1 1.0075,1.675 1.0075,2.5 L1,16 L4,13 L14.5,13 C15.325,13 16,12.325 16,11.5 L16,2.5 C16,1.675 15.325,1 14.5,1 L2.5,1 Z M4,8.5 L10,8.5 L10,10 L4,10 L4,8.5 Z M4,6.25 L13,6.25 L13,7.75 L4,7.75 L4,6.25 Z M4,4 L13,4 L13,5.5 L4,5.5 L4,4 Z"/>
                      </g>
                    </svg>
                    <span>
                      Schreibe uns!
                    </span>
                  </Button>
                  <Button
                    className="hidden lg:inline-flex"
                    aria-label="Schreibe uns!"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path fill="#2C2E33" fillRule="nonzero"
                              d="M4,4 L20,4 L20,16 L5.17,16 L4,17.17 L4,4 M4,2 C2.9,2 2.01,2.9 2.01,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 L4,2 Z M6,12 L14,12 L14,14 L6,14 L6,12 Z M6,9 L18,9 L18,11 L6,11 L6,9 Z M6,6 L18,6 L18,8 L6,8 L6,6 Z"/>
                      </g>
                    </svg>
                    <span>
                      Schreibe uns!
                    </span>
                  </Button>
                </ScrollLink>
              </div>
            </div>
            <div className="flex justify-center ml-auto">
              <button
                className="lg:hidden"
                onClick={() => {
                  setDrawerOpen(open => !open)
                }}
                aria-label="Menü öffnen"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="47" height="26" viewBox="0 0 47 26">
                  <g fill="#FFF" fillRule="evenodd">
                    <rect width="47" height="4" rx="2"/>
                    <rect width="47" height="4" y="11" rx="2"/>
                    <rect width="47" height="4" y="22" rx="2"/>
                  </g>
                </svg>
              </button>
              <Drawer
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                darkMode={darkMode}
                mainPage={mainPage}
                onChangeDarkMode={toggleDarkMode}
              />
              <nav role="navigation">
                <ul className="hidden lg:flex items-center space-x-4 xl:space-x-8">
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/ueber-uns"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Über uns
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/unser-angebot"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Unser Angebot
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/faq"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      FAQ
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/kontakt"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Kontakt
                    </ScrollLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>

        <main>
          {children}
        </main>

        <footer className="bg-dark-bg text-light-text">
          <div className="container mx-auto min-h-[500px] py-16 px-8 md:px-16 xl:px-32 flex flex-col md:flex-row space-y-16 md:space-y-0 md:space-x-16 xl:space-x-16">
            <div className="flex-1 flex items-center">
              <img src={logoFull} width="1px" height="1px" className="w-full h-auto" alt="Marco`s Fahrschule Logo"/>
            </div>
            <div className="flex-1 flex items-center justify-center">
              <nav>
                <ul className="flex flex-col gap-y-8 xl:flex-row items-center xl:gap-y-0 xl:gap-4">
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/ueber-uns"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Über uns
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/unser-angebot"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Unser Angebot
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/faq"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      FAQ
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      enabled={mainPage}
                      href="/kontakt"
                      className="px-4 py-1 hover:bg-ci-yellow hover:text-ci-gray rounded-full transition-colors"
                    >
                      Kontakt
                    </ScrollLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </footer>
      </div>
    </AppContext.Provider>
  )
}
