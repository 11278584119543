import React, {HTMLProps} from 'react'

export interface GradientContainerProps extends HTMLProps<HTMLDivElement> {
  rtl?: boolean
  containerProps?: HTMLProps<HTMLDivElement>
}

export default function GradientContainer({rtl, className = '', children, containerProps, ...props}: GradientContainerProps) {
  return (
    <div
      className={`
        relative transition-colors shadow-container from-gradient-light-start to-gradient-light-end dark:from-gradient-dark-start dark:to-gradient-dark-end
        ${rtl ? 'bg-gradient-to-bl' : 'bg-gradient-to-tr'}
        ${className}
      `}
      {...props}
    >
      <div
        {...containerProps}
        className={`
          container mx-auto py-20 lg:py-40
          ${containerProps?.className || ''}
        `}
      >
        {children}
      </div>
    </div>
  )
}
